import { collection, getDocs, orderBy, query, where } from '@firebase/firestore'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TABLE_TRANSACTION } from 'config/constants'
import { db } from 'config/db/firebase'

type TransactionState = {
  isSwapOrWithdrawOneDay: boolean
  isSwapOrWithdrawOneDayUSDT: boolean
  isSwapOrWithdrawOneDayToken: boolean
  totalAmountOfMonthWithdraw: number
  totalAmountOfWithdraw: number
}

const initialState: TransactionState = {
  isSwapOrWithdrawOneDay: false,
  isSwapOrWithdrawOneDayUSDT: false,
  isSwapOrWithdrawOneDayToken: false,
  totalAmountOfMonthWithdraw: 0,
  totalAmountOfWithdraw: 0
}

export const fetchSwapOrWithdraw = createAsyncThunk('transactionSlice/fetchSwapOrWithdraw', async (uid: string) => {
  try {
    // Current date
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    // Next date
    const date = new Date()
    date.setDate(date.getDate() + 1)
    date.setHours(0, 0, 0, 0)

    const startDate = d.getTime()
    const endDate = date.getTime()

    const transactionDocRef = collection(db, TABLE_TRANSACTION)
    // Only get first with condition swap or withdraw in current time
    const q = query(
      transactionDocRef,
      where('type', 'in', ['Swap', 'Withdraw']),
      where('uUid', '==', uid),
      where('timeMilis', '>', startDate),
      where('timeMilis', '<', endDate),
      orderBy('timeMilis', 'desc')
    )
    const swapOrWithdrawDocs = await getDocs(q)
    if (swapOrWithdrawDocs.docs.length > 0) {
      return true
    }
  } catch (error) {
    console.log('Error fetch swap or withdraw:', error)
  }
})

export const fetchSwapOrWithdrawUSDT = createAsyncThunk('transactionSlice/fetchSwapOrWithdrawUSDT', async (uid: string) => {
  try {
    // Current date
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    // Next date
    const date = new Date()
    date.setDate(date.getDate() + 1)
    date.setHours(0, 0, 0, 0)

    const startDate = d.getTime()
    const endDate = date.getTime()
    console.log('testDate')
    console.log(startDate)
    console.log(endDate)

    const transactionDocRef = collection(db, TABLE_TRANSACTION)
    // Only get first with condition swap or withdraw in current time
    const q = query(
      transactionDocRef,
      where('type', 'in', ['Swap', 'Withdraw']),
      where('uUid', '==', uid),
      where('symbol', '==', 'USDT'),
      where('timeMilis', '>', startDate),
      where('timeMilis', '<', endDate),
      orderBy('timeMilis', 'desc')
    )
    const swapOrWithdrawDocs = await getDocs(q)
    if (swapOrWithdrawDocs.docs.length > 0) {
      return true
    }
  } catch (error) {
    console.log('Error fetch swap or withdraw:', error)
  }
})

export const fetchSwapOrWithdrawToken = createAsyncThunk('transactionSlice/fetchSwapOrWithdrawToken', async (uid: string) => {
  try {
    // Current date
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    // Next date
    const date = new Date()
    date.setDate(date.getDate() + 1)
    date.setHours(0, 0, 0, 0)

    const startDate = d.getTime()
    const endDate = date.getTime()

    const transactionDocRef = collection(db, TABLE_TRANSACTION)
    // Only get first with condition swap or withdraw in current time
    const q = query(
      transactionDocRef,
      where('type', 'in', ['Swap', 'Withdraw']),
      where('uUid', '==', uid),
      where('wallet', '==', 'wToken'),
      where('timeMilis', '>', startDate),
      where('timeMilis', '<', endDate),
      orderBy('timeMilis', 'desc')
    )
    const swapOrWithdrawDocs = await getDocs(q)
    if (swapOrWithdrawDocs.docs.length > 0) {
      return true
    }
  } catch (error) {
    console.log('Error fetch swap or withdraw:', error)
  }
})

export const fetchTotalOfMonthWithdraw = createAsyncThunk('uInvestSlice/fetchTotalOfMonthWithdraw', async (uid: string) => {
  try {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const startDate = new Date(year, month).getTime()
    const endDate = new Date(year, month + 1).getTime()

    const transactionDocRef = collection(db, TABLE_TRANSACTION)

    const q = query(
      transactionDocRef,
      where('type', 'in', ['Swap', 'Withdraw']),
      where('uUid', '==', uid),
      where('timeMilis', '>', startDate),
      where('timeMilis', '<', endDate),
      orderBy('timeMilis', 'desc')
    )

    const investOfMonth = await getDocs(q)

    let totalAmount = 0

    if (investOfMonth.docs.length > 0) {
      investOfMonth.docs.forEach(doc => {
        totalAmount += doc.data().amount
      })
    }

    return totalAmount * -1
  } catch (error) {
    console.log('Error fetch swap or withdraw:', error)
  }
})

export const fetchTotalWithdraw = createAsyncThunk('uInvestSlice/fetchTotalWithdraw', async (uid: string) => {
  try {
    const transactionDocRef = collection(db, TABLE_TRANSACTION)
    const q = query(transactionDocRef, where('type', 'in', ['Swap', 'Withdraw']), where('uUid', '==', uid))

    const investOfMonth = await getDocs(q)

    let totalAmount = 0

    if (investOfMonth.docs.length > 0) {
      investOfMonth.docs.forEach(doc => {
        totalAmount += doc.data().amount
      })
    }

    return totalAmount * -1
  } catch (error) {
    console.log('Error fetch swap or withdraw:', error)
  }
})

export const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // Current swap or withdraw
    [fetchSwapOrWithdraw.fulfilled.toString()]: (state, { payload }) => {
      state.isSwapOrWithdrawOneDay = payload
    },
    [fetchSwapOrWithdrawUSDT.fulfilled.toString()]: (state, { payload }) => {
      state.isSwapOrWithdrawOneDayUSDT = payload
    },
    [fetchSwapOrWithdrawToken.fulfilled.toString()]: (state, { payload }) => {
      state.isSwapOrWithdrawOneDayToken = payload
    },
    // Get total of month withdraw
    [fetchTotalOfMonthWithdraw.fulfilled.toString()]: (state, { payload }) => {
      state.totalAmountOfMonthWithdraw = payload
    },
    // Get total of withdraw
    [fetchTotalWithdraw.fulfilled.toString()]: (state, { payload }) => {
      state.totalAmountOfWithdraw = payload
    }
  }
})

export default transactionSlice.reducer
